<template>
  <div class="register-page finish">
    <div class="container-wide">
      <img src="/static/img/logo-soco.svg" class="logo-soco" alt="register-cover-image" />
      <img src="/static/img/finish-register.svg" class="img-responsive" alt="register-cover-image" />
      <h2 class="register-tile">CONGRATULATIONS!</h2>
      <p class="main-desc text-center">
        <strong>You are now a SOCO member.</strong>
      </p>
      <p class="main-desc text-center">
        Don't forget to explore SOCO through <strong>soco.id</strong> website. Get various interesting recommendation,
        promos and much more!
      </p>

      <div class="fixed-bottom">
        <button class="btn btn-full finish" type="submit" @click.prevent="logout()">LOG OUT</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'register',
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    logout() {
      this.$MS_SOCO_PUBLIC_API_URL.get('/auth/logout').then((res) => {
        if (res.data.success) {
          this.$toasted.global.show('Logout berhasil');
          this.$router.push('/register');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.register-page.finish .container-wide {
  display: initial !important;
}
</style>
